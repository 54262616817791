import { render, staticRenderFns } from "./Prlekija.vue?vue&type=template&id=90ebec3a&scoped=true&"
import script from "./Prlekija.vue?vue&type=script&lang=js&"
export * from "./Prlekija.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90ebec3a",
  null
  
)

export default component.exports