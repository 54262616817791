<template>
    <div class="p-3">
        <!--  title  -->
        <h4>Prelepa Prlekija</h4>
        <!-- o Prlekiji -->
        <b-row>
            <b-col>
                <p><b>Prlekija je del Slovenskih goric med rekama Dravo in Muro, ki se razteza od meje z Avstrijo do
                    meje s Hrvaško. Skupaj s Prekmurjem tvori regijo Pomurje, njeno kulturno in regijsko središče pa je
                    Ljutomer. </b>
                </p>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <img class="mr-2" src="../../../assets/images/prlekija/P1010100.jpg" height="auto" width="100%"/>
            </b-col>
            <b-col>
                <img src="../../../assets/images/prlekija/P1010159.jpg" height="auto" width="100%"/>
            </b-col>
        </b-row>
        <br>
        <b-row>
            <b-col>
                <p>Majhna, a slikovita pokrajina slovi po odličnem vinu in tradicionalnih kulinaričnih dobrotah.
                    Družinske kmetije tega področja poleg izjemnih vin nudijo tudi turistične nastanitve in doživetja,
                    predvsem pa raznovrstne izdelke, vse od prleške tünke in drugih mesnih dobrot, mlečnih izdelkov in
                    prleške gibanice, pa vse do sadnih sokov, domačih mok, testenin, namazov ter izdelkov iz sivke,
                    aronije, medu in raznovrstnih olj, predvsem bučnega, za katerega pravijo, da poleg mineralne vode v
                    Prlekiji teče kar iz pipe. </p>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <img src="../../../assets/images/prlekija/20200915_073934.jpg" height="auto" width="100%"/>
            </b-col>
            <b-col>
                <img src="../../../assets/images/prlekija/20200915_074647.jpg" height="auto" width="100%"/>
            </b-col>
        </b-row>
        <br>
        <b-row>
            <b-col>
                <img src="../../../assets/images/prlekija/P9220234.jpg" height="auto" width="100%"/>

            </b-col>
            <b-col>
                <img src="../../../assets/images/prlekija/P9220252.jpg" height="auto" width="100%"/>

            </b-col>
        </b-row>
        <br>
        <b-row>
            <b-col>
                <p>Klopotec, mlin in brod so poleg štorklje, čaplje in srne pravi znanilci Prlekije. Stare domače
                    obrti, kot so medičarstvo, sodarstvo in lončarstvo, še sedaj živijo, prleščine pa ne moremo
                    zamenjati ne s prekmurščino ne s primorščino. Prleki in Prlečke namreč gučijo po prleško (ki sicer
                    sodi v slovenskogoriško narečje večje štajerske narečne skupine). </p>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <img src="../../../assets/images/prlekija/P9220157.jpg" height="auto" width="100%"/>
            </b-col>
            <b-col>
                <img src="../../../assets/images/prlekija/P9220025.jpg" height="auto" width="100%"/>
            </b-col>
        </b-row>
        <br>
        <b-row>
            <b-col>
                <img src="../../../assets/images/prlekija/P1010009.jpg" height="auto" width="100%"/>
            </b-col>
            <b-col>
                <img src="../../../assets/images/prlekija/grapes.jpg" height="auto" width="100%"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "Prlekija",
    components: {},
    props: {},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
